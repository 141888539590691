import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { markdownConverter } from "../utils/markdown";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import ImageMosaic from "../components/ImageMosaic";

const StoryPage = ({ data }) => {
  const remark = data.allMarkdownRemark.edges[0].node;
  const { frontmatter: pageData } = remark;

  const mosaicImages = (images) => {
    let returning = [];
    images.forEach((image) => {
      returning.push({
        img: image.image_object.img,
        rows: image.image_object.rows,
        cols: image.image_object.cols,
      });
    });

    return returning;
  };

  return (
    <Layout title="Yrityksen tarina">
      <div className="page section">
        <div className="container story-page">
          <PageHeader
            title={pageData.pageTitle}
            description={pageData.pageDescription}
            image={pageData.pageImage}
          />
          <div className="story-page__content">
            {pageData.story.map((storyBlock, index) => (
              <div key={index} className="story-container mt-3">
                <div
                  className="text-container"
                  dangerouslySetInnerHTML={{
                    __html: markdownConverter.makeHtml(
                      storyBlock.story_content.body
                    ),
                  }}
                ></div>
                <div className="mt-2">
                  <ImageMosaic
                    images={mosaicImages(storyBlock.story_content.images)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

StoryPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default StoryPage;

export const pageQuery = graphql`
  query StoryPageQuery {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "story-page" } } }
    ) {
      edges {
        node {
          html
          frontmatter {
            templateKey
            pageTitle
            pageDescription
            pageImage
            story {
              story_content {
                body
                images {
                  image_object {
                    img
                    cols
                    rows
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
